<template>
  <div class="container flex min-h-screen flex-col items-center justify-center">
    <div class="w-full md:max-w-md">
      <img
        class="mx-auto mb-16 h-32"
        src="~/assets/img/login-logo.png"
        alt="Bidfood Connect"
      >
      <slot />
    </div>
  </div>
</template>
